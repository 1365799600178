<template>
  <div>
    <b-row align-v="center" class="d-flex align-items-center">
      <b-col cols="6" class="mb-2">
        <label>Tenant</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="tenantFilter"
            :options="tenantOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:tenantFilter', val)"
            append-to-body
            :calculate-position="withPopper"
          />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import popperHelper from "@/libs/popper-helper"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array,
      default: null,
    },
  },
  setup() {
    return {
      withPopper: popperHelper.withPopper,
    };
  }
};
</script>
